body *::-webkit-scrollbar {
    width: 0.25em;
    height: 0.25em;
}

body *::-webkit-scrollbar-thumb {
    background: #96989B;
    border-radius: 10px;
    opacity: 0.7;
    overflow: auto;
}
